import * as React from "react";
// import { Link } from "react-router-dom";
import Card from "components/Card";
import { useParams } from "react-router-dom";

import { useGenericAPI } from "utils/hooks/useGenericAPI";
import { Loading } from "components/Loading";
import { parseNumber } from "utils/membership";

export const PageCard = () => {
  const register = parseNumber(window.location.pathname.replace("/", ""));
  const { id } = useParams();
  const { getRequest, loading, data, error } = useGenericAPI(`user_profiles/`);

  let isValid = false;
  let member = false;
  let hasError = false;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getRequest(id), []);
  hasError = !!error?.error;

  if (loading) {
    return <Loading text="Procurando perfil..." />;
  }

  if (!hasError && data) {
    const { membership, user_profile } = data;

    if (membership && user_profile) {
      member = {
        CATEGORIA: membership?.membership_group?.name,
        NOME: user_profile?.full_name,
        VALIDADE: membership?.valid_until,
        CARTEIRA: membership?.register_number,
        PHOTO: membership?.card_picture || membership?.photo_url,
      };

      isValid = true;
    }
  }

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col">
          <div className="text-center Header">
            <img
              src="/citrg-logo.png"
              alt="Logo do conselho"
              className="membership-photo"
              width={100}
            />

            {isValid && <h2 className="my-3 my-lg-5">Terapeuta Certificado</h2>}
            {!isValid && (
              <>
                <h2 className="my-3 my-lg-5">
                  Carteira digital não encontrada.
                </h2>

                <p>
                  Se sua documentação já foi aprovada, você possui uma filiação
                  válida e sem pendências financeiras, fique tranquilo! Assim
                  que sua carteira for emitida, sua carteira digital ficará
                  disponível neste endereço.
                </p>
              </>
            )}
          </div>

          {isValid && <Card member={member} register={register} />}

          {isValid && (
            <div className="text-center mt-3 m-sm-5">
              <hr />
              {/* <Link to={`/perfil/${register}`}>Ver perfil deste terapeuta</Link> */}
              <p className="mt-3">
                Este terapeuta é certificado pela URL oficial do CITRG. Para
                conferência dos dados, sempre utilize o endereço
                https://www.cbtrg.com.br/{register}.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageCard;
