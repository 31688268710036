import React from "react";
import { QRCodeSVG } from "qrcode.react";

export const Card = ({ member }) => {
  const memberNumber =
    member["CARTEIRA"].slice(0, 2) + "." + member["CARTEIRA"].slice(2);

  return (
    <div>
      <div className="CardWrapper">
        <div className={`Card Card--${member["CATEGORIA"]}`}>
          <div className={`Card__Badge Card__Badge--${member["CATEGORIA"]}`}>
            <div
              className={`Card__QrCode Card__QrCode--${member["CATEGORIA"]}`}
            >
              <QRCodeSVG value="https://citrg.com/" size={115} />
            </div>

            <p>
              <span>{member["CATEGORIA"]}</span>
              <span>member</span>
            </p>
          </div>
          <div className="Card__Header">
            <img src={`cbtrg-logo.png`} alt="CITRG LOGO" />
          </div>

          <div className="Card__Body">
            <div className="Card__Image">
              <img
                alt={`Imagem de ${member["NOME"]}`}
                src={member["PHOTO"]}
                className="img-fluid"
              />
            </div>

            <div className="Card__Info">
              <span className="Card__InfoName">
                <h3>Nome Completo</h3>
                <p>{member["NOME"]}</p>
              </span>
              <span className="Card__Valid">
                <span>
                  <h3>Registro</h3>
                  <p>{memberNumber}</p>
                </span>
                <span>
                  <h3>Validade</h3>
                  <p>{member["VALIDADE"]}</p>
                </span>
              </span>
              <div className="Card__TRG__Logo">
                <img alt="TRG LOGO" src="TRG-logo.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
